import React from 'react';
import { Switch, Route } from "react-router-dom";
import axios from './config/axios';
import axiosStatic from 'axios';
import history from './config/history';
import { TMainLanguage, TMainTemplate, TCurrentRoute, ContextAppSettings, IForm } from './config/context';
import { UIState } from './config/variables';
import { MembersTypeRes } from './Models/ResponseModels/Members';
import PageNotFound from './Components/_UI/ErrorHandler/PageNotFound';
import PrivateRoute from './Components/_UI/PrivateRoute';
import Navigation from './Components/Navigation/Navigation';
import MemberLog from './Models/Log/MemberLog';
import { saveLog, scrollToElementWithId, hideLoader, showLoader, updateUserLanguage, filterMadridEvents } from './config/functions';
import { ToastContainer } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import Notifications from "./Components/Notifications/Notifications";
// Login
import ForgotPassword from './Containers/ForgotPassword/ForgotPassword';
import ChangePassword from './Containers/ForgotPassword/ChangePassword';
import RegisterPage from './Containers/RegisterPage/RegisterPage';
import RegisterSuccess from './Components/RegisterPage/RegisterSuccess';

// Footer
import Footer from './Components/Footer/Footer';
import { EventsTypeRes } from './Models/ResponseModels/Events';
import { EventUtility } from './Models/Utility';
import { getInitialHistoryStack, setCustomDB } from './config/globalFunctions';
import { Enum } from './Models/Enum';

import moment from 'moment';

// Locale for moment.js
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/fr';
import ZoomFallbackContainer from './Containers/Zoom/ZoomFallbackContainer';
import { AchievementUnlocked } from './Components/_hooks/useAchievements';
import { AchievementsTypeRes } from './Models/ResponseModels/Achievements';
import TensChallenge from './Containers/TensChallenge/TensChallenge';
import AudioManager from './Components/Utility/AudioManager';
import ManagerEventSteps from './Components/Manager/ManagerEventSteps';
import ExhibitionAreaPreview from './Containers/ExhibitionArea/ExhibitionAreaPreview';
import { MpaUi } from '@mixerpa/mpa-react-components.mpa-ui';
import InPerson from './Containers/InPerson/InPerson';
import SignalRManager from './Components/Manager/SignalRManager';
import ManagerOneSignal from './Components/Manager/ManagerOneSignal';
import { InPersonLiveSingleTool } from './Components/InPerson/LiveTools/InPersonLiveTools';
import Editor from './Containers/DigitalJournal/Panel/EditorJournal';
import Preview from './Containers/DigitalJournal/Preview/Preview';
import SvgGradients from './Components/_UI/SvgGradients';
import ModalMenu from './Components/_UI/Modal/ModalMenu';
import CheckLoginStatus from './Components/CheckLoginStatus';
import RedirectTo from './Components/RedirectTo';

const DownloadCertificate = React.lazy(() => import('./Components/DownloadCertificate/DownloadCertificate'));

// Pages
const PersonalBadge = React.lazy(() => import('./Containers/PersonalBadge/PersonalBadge'));
const BusinessCard = React.lazy(() => import('./Containers/BusinessCard/businessCard'));
// Footer legal pages
const LegalNotice = React.lazy(() => import('./Components/Legal/LegalNotice'));
const TermsService = React.lazy(() => import('./Components/Legal/TermsService'));
const PrivacyPolicy = React.lazy(() => import('./Components/Legal/PrivacyPolicy'));
const CookiesNotice = React.lazy(() => import('./Components/Legal/CookiesNotice'));

// Contact Faculty Pm
// const ContactFacultyPM = React.lazy(() => import('./Components/ContactFaculty/ContactFacultyPM'));

// Chat panel
const LiveChat = React.lazy(() => import('./Containers/Chat/LiveChat'));
const BackstageChat = React.lazy(() => import('./Containers/Chat/BackstageChat'));
const FacultyPanel = React.lazy(() => import('./Containers/FacultyPanel/FacultyPanel'));

// Events - edit event active
const EventAdmin = React.lazy(() => import('./Containers/Event/EventAdmin'));
const EventsAdminMadrid = React.lazy(() => import('./Containers/Event/EventsAdminMadrid'));

// File viewers
const VideoViewer = React.lazy(() => import('./Components/FileViewer/VideoViewer'));

// Cvent import
const EventsList = React.lazy(() => import('./Containers/Cvent/EventsList'));
const EventAttendees = React.lazy(() => import('./Containers/Cvent/EventAttendees'));
const AvaiableUsers = React.lazy(() => import('./Containers/Cvent/AvaiableUsers'));
const Cvent = React.lazy(() => import('./Containers/Cvent/Cvent'));
const CventGuestsList = React.lazy(() => import('./Components/Cvent/CventGuestsList'));

const LandingPageContainer = React.lazy(() => import('./Containers/LandingPage/LandingPageContainer'));
const InfoPointPageContainer = React.lazy(() => import('./Containers/InfoPointPage/InfoPointPageContainer'));
const RegistrationPageContainer = React.lazy(() => import('./Containers/RegisterPage/RegistrationPageContainer'));
const HomePageContainer = React.lazy(() => import('./Containers/HomePage/HomePageContainer'));
const LiveEventPageContainer = React.lazy(() => import('./Containers/LivePage/LiveEventPageContainer'));
const PostLiveEventPageContainer = React.lazy(() => import('./Containers/PostLive/PostLiveEventPageContainer'));
const BreakoutRoom = React.lazy(() => import('./Containers/Zoom/BreakoutRoom'));
const ZoomMeetingsList = React.lazy(() => import('./Containers/Zoom/ZoomMeetingsList'));
const LoungeCafe = React.lazy(() => import('./Containers/LoungeCafe/LoungeCafe'));
const LoungeCafeRoom = React.lazy(() => import('./Containers/LoungeCafe/LoungeCafeRoom'));
const BackstageArea = React.lazy(() => import('./Containers/BackstageArea/BackstageArea'));
const ViewsPanel = React.lazy(() => import('./Containers/Statistics/ViewsPanel'));
const NotificationsPanel = React.lazy(() => import('./Containers/Notifications/NotificationsPanel'));
const OnDemandArea = React.lazy(() => import('./Containers/OnDemandArea/OnDemandArea'));
const ExhibitionArea = React.lazy(() => import('./Containers/ExhibitionArea/ExhibitionArea'));
const ExhibitionAreaConfigurator = React.lazy(() => import('./Containers/ExhibitionArea/ExhibitionAreaConfigurator'));
const RemoteClicker = React.lazy(() => import('./Containers/FacultyPanel/RemoteClicker'));
const Survey = React.lazy(() => import('./Containers/Survey/EventSurvey'));
const EmeaAwardsTrip = React.lazy(() => import('./Containers/CustomPages/EmeaAwardsTrip'));
const StLucia = React.lazy(() => import('./Containers/StLucia/stLucia'));
const Vietnam = React.lazy(() => import('./Containers/Vietnam/vietnam'));
const MadridPhotoAlbum = React.lazy(() => import('./Containers/CustomPages/MadridPhotoAlbum'));
const GalleryHeroes = React.lazy(() => import('./Containers/GalleryHeroes/GalleryHeroes'));
const GalleryHeroesWrapper = React.lazy(() => import('./Containers/GalleryHeroes/GalleryHeroesWrapper'));
const VideoLibraryTeamMembers = React.lazy(() => import('./Containers/CustomPages/VideoLibraryTeamMemebrs'));
const WarehouseArea = React.lazy(() => import('./Containers/WarehouseArea/WarehouseArea'));
const UsersList = React.lazy(() => import('./Containers/UsersList/UsersList'));
const BookingsPanel = React.lazy(() => import('./Containers/Bookings/BookingsPanel'));
const VotingPanel = React.lazy(() => import('./Containers/Voting/VotingPanel'));
const InPersonInteractiveProgram = React.lazy(() => import('./Components/InPerson/Program/InPersonInteractiveProgram'));
const PanelDiscussion = React.lazy(() => import('./Containers/MadridDigital/PanelDiscussion'));
const MeetTheExpert = React.lazy(() => import('./Containers/MadridDigital/MeetTheExpert'));
const InPerson3DInteractiveMap = React.lazy(() => import('./Components/InPerson/InPerson3DInteractiveMap'));

const InPersonEJournal = React.lazy(() => import('./Components/InPerson/InPersonEJournal'));
const Gallery = React.lazy(() => import('./Containers/DigitalJournal/Gallery'));

const Journal = React.lazy(() => import('./Containers/DigitalJournal/Journal'));
const DigitalBlackboard = React.lazy(() => import('./Containers/DigitalJournal/DigitalBlackboard'));
const QrResource = React.lazy(() => import('./Containers/Other/QrResource'));
const InstallInstructions = React.lazy(() => import('./Containers/Other/InstallInstructions'));
const MadridShoulderLandingPage = React.lazy(() => import('./Containers/MadridShoulder/LandingPage'));
const ContemporaryApproachesInfectionLandingPage = React.lazy(() => import('./Containers/ContemporaryApproachesInfection/LandingPage'));

const AgendaAppointmentsList = React.lazy(() => import('./Containers/Management/AgendaAppointmentsList'));
const DigiPad = React.lazy(() => import('./Containers/DigiPad/DigiPad'));


// Wheel Game
const WheelOfFortune = React.lazy(() => import('./Containers/WheelOfFortune/WheelOfFortune'));
const WheelOfFortuneLeaderboard = React.lazy(() => import('./Containers/WheelOfFortune/WheelOfFortuneLeaderboard'));
const WheelOfFortuneLive = React.lazy(() => import('./Containers/WheelOfFortune/WheelOfFortuneLive'));

const PostLogin = React.lazy(() => import('./Components/PostLogin'));

interface IProps {
  location?: string
}

export interface IHistoryStack {
  key: string,
  path: string,
}

interface IState {
  authToken: string | null,
  myProfileData: MembersTypeRes.IMember | null,
  myGroupData: MembersTypeRes.IGroupAssociation | null,
  apiKeepAliveTO: any,
  wsKeepAliveTO: any,
  isNavVisible: boolean,
  path: string,
  isRegistrationEnabled: boolean,                 // true: register visible, on login goes to edit profile, false: no register, on login goes to landing
  language: TMainLanguage,
  activeForm: IForm,
  initShowModalLogin: boolean,
  /* Events releated fields (avaiable in context) */
  events: EventsTypeRes.IEvent[],
  currentRoute: TCurrentRoute,
  currentEvent: EventsTypeRes.IEvent | null,
  uiState: number,
  template: TMainTemplate,
  msTeamsData: string,
  isPollsVisible: boolean,
  isChatVisible: boolean,
  eventsLight: EventsTypeRes.IEventLight[],
  isAttendeeOfCurrentEvent: boolean,
  isDemoEvent: boolean,
  isTens2022: boolean,
  isMadridDigital: boolean,
  isMadridInPerson: boolean,
  achievements: AchievementsTypeRes.IAchievement[] | null,
  setAchievements: Function | null,
  isMounted: boolean,
}

let settingEvent = false;
let hideLoaderTimeout: NodeJS.Timeout;
// Contains each page the user navigate into, it's used to determinate if the back button should be disabled or not.
let historyStack: IHistoryStack[] = getInitialHistoryStack();

class App extends React.Component<IProps, IState> {

  componentsByState = {
    1: LandingPageContainer,
    2: InfoPointPageContainer,
    3: HomePageContainer,
    4: InfoPointPageContainer,
    5: PostLiveEventPageContainer
  };

  state: IState = {
    authToken: window.storageGetItemValue('Auth-token'),
    myProfileData: null,
    myGroupData: null,
    apiKeepAliveTO: null,
    wsKeepAliveTO: null,
    isNavVisible: true,
    path: "",
    isRegistrationEnabled: true,
    language: window.storageGetItemValue('Language'),
    activeForm: 'default',
    initShowModalLogin: false,
    /* Events releated fields (avaiable in context) */
    events: [],
    currentRoute: '',
    currentEvent: null,
    uiState: 0,
    template: 'Template01',
    msTeamsData: '',
    isPollsVisible: false,
    isChatVisible: false,
    eventsLight: [],
    isAttendeeOfCurrentEvent: false,
    isDemoEvent: false,
    isTens2022: false,
    isMadridDigital: false,
    isMadridInPerson: false,
    achievements: null,
    setAchievements: null,
    isMounted: false,
  }

  componentWillMount() {
    // Intial loader:
    showLoader();

    // var OneSignal = window.OneSignal || [];

    // Dev
    window.apiURL = process.env.REACT_APP_API_URL || '';
    window.wsURL = process.env.REACT_APP_WEBSOCKET_URL || '';
    axios.defaults.baseURL = window.apiURL;
    window.debug = (process.env.REACT_APP_DEBUG == 'true');
    window.authenticationMode = 'API';
    window.sessionLength = parseInt(process.env.REACT_APP_SESSION_LENGTH || '5');

    // if (process.env.NODE_ENV === 'development') {
    //   window.debug = true;
    //   window.sessionLength = 1;
    //   window.authenticationMode = "API";
    // }
    // // Test 1
    // else if (process.env.REACT_APP_STATE === 'test') {
    //   window.debug = true;
    //   window.sessionLength = 15;
    //   window.authenticationMode = "API";
    // }
    // // Test 2
    // else if (process.env.REACT_APP_STATE === 'testDB2') {
    //   window.debug = false;
    //   window.sessionLength = 15;
    //   window.authenticationMode = "API";
    // }
    // // Production
    // else {
    //   window.debug = false;
    //   window.sessionLength = 15;
    //   window.authenticationMode = "API";
    // }

    // Set custom DB for storing files.
    setCustomDB();

    //init onesignal
    // var OneSignal = window.OneSignal || [];

    // if (process.env.REACT_APP_ONESIGNAL_APP_ID && OneSignal) {
    //   OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID }).then(() => {
    //     OneSignal.showSlidedownPrompt().catch((e: any) => {
    //       console.log("error OneSignal ", e);
    //     });
    //     OneSignal.on('subscriptionChange', function (isSubscribed: boolean) {
    //       console.log("isSubscribed", isSubscribed);
    //       OneSignal.getUserId().then((userId) => {
    //         axiosInstance.post('Members/ManagePushDevice', { "deviceID": userId, "enabled": isSubscribed });
    //         console.log("userId ", userId + " isSubscribed ", isSubscribed)
    //       })
    //     });
    //   })
    // }

    // var OneSignal = window.OneSignal || [];
    // console.log("OneSignal ", OneSignal)
    // OneSignal.push(function () {
    //   console.log("OneSignal  ID", process.env.REACT_APP_ONESIGNAL_APP_ID)
    //   OneSignal.init({
    //     appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    //   });

    //   OneSignal.showSlidedownPrompt();

    //   OneSignal.on('subscriptionChange', function (isSubscribed: any) {
    //     //var memberID = window.storageGetItem("Auth-memberID");

    //     console.log("isSubscribed ", isSubscribed)
    //     if (isSubscribed) {
    //       console.log("isSubscribed ", isSubscribed)
    //       // The user is subscribed
    //       OneSignal.getUserId(function (userId: string) {
    //         axiosInstance.post('Members/ManagePushDevice', { "deviceID": userId, "enabled": true });
    //       });
    //     }
    //     else {
    //       console.log("isSubscribed ", isSubscribed)
    //       OneSignal.getUserId(function (userId: string) {
    //         axiosInstance.post('Members/ManagePushDevice', { "deviceID": userId, "enabled": false });
    //       });
    //     }
    //   });
    // });
    // window.OneSignal = OneSignal;
    // if (process.env.REACT_APP_ONESIGNAL_APP_ID && OneSignal) {

    //   OneSignal.push(function () {

    //     console.log("sono in push")

    //     OneSignal.init({
    //       appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    //     });

    //     OneSignal.showSlidedownPrompt();

    //     OneSignal.on('subscriptionChange', function (isSubscribed: boolean) {
    //       console.log("isSubscribed", isSubscribed);
    //       OneSignal.getUserId().then((userId: string) => {
    //         axiosInstance.post('Members/ManagePushDevice', { "deviceID": userId, "enabled": isSubscribed });
    //         console.log("userId ", userId + " isSubscribed ", isSubscribed)
    //       })
    //     });

    //   })
    // }
    // This state is muted directly because don't have to cause components refreshes
    this.state.apiKeepAliveTO = setTimeout(this.apiKeepAlive, 1 * 60 * 1000);
    this.state.setAchievements = this.setAchievements;

    // Initial language:
    if (!this.state.language) {
      // const avaiableLanguage = ['en', 'de', 'es', 'it', 'fr']
      const avaiableLanguage = ['en', 'de', 'it']
      let language = window.storageGetItemValue("language");
      if (!language) {
        let browserLanguage = window.navigator.language;
        if (browserLanguage.length > 2) {
          browserLanguage = browserLanguage.substring(0, 2);
        }
        if (browserLanguage && avaiableLanguage.indexOf(browserLanguage) > -1) {
          language = browserLanguage;
        } else {
          language = 'en';
        }
        window.storageSetItem("language", language);
      }
      // If the cached language it's not in the avaiable list, we force the language to "en"
      else if (avaiableLanguage.indexOf(language) === -1) {
        language = 'en';
        window.storageSetItem("language", language);
      }
      this.setState({ language: language })
      moment.locale(language);
    }

    // Initial location:
    this.updateHistoryStack(history.location.key ? history.location.key : "", history.location.pathname);
  }

  apiKeepAlive = () => {
    const token = window.storageGetItemValue("Auth-token");
    if (window.debug) console.log("App.tsx", "APIKeepAlive called (due inactivity on api calls)", new Date(Date.now()).toISOString())
    if (token != null) {

      var dateDiff = Date.now() - window.lastApiRequest;

      if (dateDiff >= window.sessionLength * 60 * 900) { //non *1000 per cambiarlo appena prima della scadenza...
        if (window.debug) console.log("App.tsx", "API KeepAlive call - Changing token!");
        axios.get('Authentication/TokenRefresh').then(res => {
          // Updated manually (and not thorugh this.setState) because it doesn't have to cause components refresh!
          if (res && res.headers && res.headers["x-token"]) {
            this.state.authToken = res.headers["x-token"];
          }
        });
      }
    }

    // Updated manually (and not thorugh this.setState) because it doesn't have to cause components refresh!
    this.state.apiKeepAliveTO = setTimeout(this.apiKeepAlive, 1 * 60 * 1000);
  }

  setAchievements = (callback: Function | AchievementsTypeRes.IAchievement[]) => {
    let values: AchievementsTypeRes.IAchievement[] = [];
    if (typeof (callback) === 'function') {
      values = callback(this.state.achievements);
    }
    else {
      values = callback;
    }
    this.setState({ achievements: values });
  }

  async componentDidMount() {
    let showLoading = true;
    window.clearAuthentication = this.clearAuthentication;
    window.updateLanguage = this.setLanguage;
    window.updateUIState = this.setUIState;
    window.updateTemplate = this.setTemplate;

    if (window.storageGetItemValue("version") !== 4) {
      window.storageSetItem("version", 4);
      window.location.reload();
    }

    const deviceToken = window.storageGetItemValue("Auth-device-token");
    if (deviceToken) {
      // Auto-Login with device token:
      showLoader();
      showLoading = false;
      const data = {}
      const config = {
        headers: { Authorization: deviceToken }
      }
      const axiosAutoLogin = axiosStatic.create({
        baseURL: window.apiURL
      });
      await axiosAutoLogin.post("Authentication/autoLogin", data, config).then(res => {
        const response = res.data;
        if (response.status === "success") {
          window.storageSetItem("Auth-token", response.data.token);
          this.setState({ authToken: response.data.token });
          window.storageSetItem("Auth-device-token", response.data.deviceToken);
          this.getExistingEvents(response.data.token)
          this.getProfileData(showLoading);
        }
      }).catch(e => {
        this.setState({ isMounted: true });
        console.log("Authentication/autoLogin - error", e);
        if (history.location.pathname.indexOf("/login") === -1) {
          history.push(`/login`);
        }
        window.removeStoredUserData();
      });
    } else {
      // No device token found:
      this.getExistingEvents();
      if (this.state.authToken) {
        this.getProfileData(showLoading);
      } else {
        this.setState({ isMounted: true });
        // if (history.location.pathname.indexOf("/login") === -1) {
        //   console.log("i AM the bug")
        //   history.push(`/login`);
        // }
        window.removeStoredUserData();
      }
    }

    window.addEventListener("beforeunload", this.beforeUnload);
    window.addEventListener("reloadProfile", () => { this.getProfileData(false) });
    window.addEventListener("updateEventAttendees", this.updateEventAttendees);
    window.addEventListener("updateCurrentEvent", this.updateCurrentEvent);
    window.addEventListener("clearHideLoaderTimer", this.clearHideLoaderTimer);
    window.addEventListener("popstate", this.checkHistory);

    this.setState({ path: history.location.pathname });

    history.listen((location, action) => {
      let path = location.pathname;
      if (!settingEvent || path === '/') {
        this.updateSettingsByEvent();
      }
      this.updateHistoryStack(location.key ? location.key : "", path);

      // check for sw updates on page change
      /* navigator.serviceWorker
        .getRegistrations()
        .then((regs) => regs.forEach((reg) => reg.update())); */
    });
  }

  getExistingEvents = (authToken?: string) => {
    axios.get('Events/ExistingEvents').then(res => {
      if (res.data.status === "success") {
        let eventsLight: EventsTypeRes.IEventLight[] = res.data.data.collection;
        this.setState({ eventsLight: eventsLight });
        var parts = window.location.pathname.split(/\/|\?/);
        let eventPath = '';
        eventPath = eventsLight.find(e => e.public === false && parts.find(part => part === e.eventPath))?.eventPath || '';
        if (eventsLight && eventPath !== '' && !this.state.authToken && !authToken) {
          this.setState({ isMounted: true, initShowModalLogin: true })
          window.storageSetItem("redirectTo", window.location.pathname + window.location.search, true);
          // this.setFormActive('login')
          if (history.location.pathname.indexOf("/login") === -1) {
            history.push(`/login`);
          }
        }
        this.setEventSelected();
      }
    }).catch(e => {
      console.log("Events/ExistingEvents - error", e);
      this.setState({ isMounted: true });
      if (history.location.pathname.indexOf("/login") === -1) {
        history.push(`/login`);
      }
    })
  }

  checkHistory = (e: any) => {
    if (e.state && e.state.key) {
      let index = historyStack.findIndex(x => x.key === e.state.key);
      // Remove from history stack, the current page before redirect to the previous page:
      if (index >= 0 && index + 1 < historyStack.length) {
        this.handleHistoryBack(historyStack[index + 1].key);
      }
      if (index >= 0 && historyStack[index].path.indexOf("/login") > -1 && this.state.authToken) {
        this.handleHistoryBack(historyStack[index].key);
      }
    }
  }

  handleHistoryBack = (key: string) => {
    historyStack = historyStack.filter(x => x.key !== key);
    sessionStorage.setItem("historyStack", JSON.stringify(historyStack));
  }

  updateHistoryStack = (key: string, path: string) => {
    if (historyStack.findIndex(x => x.key === key) === -1) {
      if (historyStack.length > 0 &&
        historyStack[historyStack.length - 1].path === path) {
        historyStack.pop();
      }
      historyStack.push({ key: key, path: path });
      sessionStorage.setItem("historyStack", JSON.stringify(historyStack));
    }
    this.setState({ path: path });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload);
    window.removeEventListener("updateEventAttendees", this.updateEventAttendees);
    window.removeEventListener("updateCurrentEvent", this.updateCurrentEvent);
    window.removeEventListener("clearHideLoaderTimer", this.clearHideLoaderTimer);
    window.removeEventListener("popstate", this.checkHistory);
  }

  updateEventAttendees = (e: any) => {
    const event = e as CustomEvent<EventUtility.IEventAttendeeUpdate>;
    const { eventID, eventAttendee } = event.detail;

    if (eventAttendee) {
      // We update eventAttendee list of the specificed event passed in "event.detail"
      this.setState(prev => {
        const eventIndex = prev.events.findIndex(x => x.eventID === eventID);
        const eventToUpdate = prev.events[eventIndex];
        if (eventToUpdate.eventAttendees?.length > 0) {
          const attendeeIndex = eventToUpdate.eventAttendees.findIndex(x => x.memberID === eventAttendee.memberID);
          eventToUpdate.eventAttendees[attendeeIndex] = eventAttendee;
        }
        else {
          eventToUpdate.eventAttendees = [];
          eventToUpdate.eventAttendees.push(eventAttendee)
        }
        return ({
          events: prev.events
        });
      });
    }
  }

  updateCurrentEvent = (e: any) => {
    const eventUpdate = e as CustomEvent<EventUtility.IEventUpdate>;
    const { eventID, event, updateCurrentEvent } = eventUpdate.detail;

    if (event) {
      // Update current event
      if (updateCurrentEvent) {
        this.setState({ currentEvent: event });
      }

      // Update current event inside events collection
      this.setState(prev => {
        const eventIndex = prev.events.findIndex(x => x.eventID === eventID);
        let eventToUpdate = prev.events[eventIndex];
        eventToUpdate = event;
        prev.events[eventIndex] = eventToUpdate;
        return ({
          events: prev.events
        });
      });
    }
  }

  getProfileData = (loading: boolean) => {
    if (loading) {
      showLoader();
    }
    axios.get('Members/GetMyProfile').then(res => {
      const profileResponse = res.data;
      if (profileResponse.status === "success") {
        window.storageSetItem("Auth-memberID", profileResponse.data.memberID);
        window.storageSetItem("Auth-memberTypeID", profileResponse.data.memberTypeID);
        window.storageSetItem("Auth-roles", profileResponse.data.memberRoles);
        window.storageSetItem("isFirstAccess", profileResponse.data.isFirstAccess ? 'S' : 'N');

        const myProfileData: MembersTypeRes.IMember = profileResponse.data;
        this.setProfileData(myProfileData);
      }
      if (loading) {
        hideLoaderTimeout = setTimeout(() => { hideLoader(); }, 300);
      }
      this.setState({ isMounted: true });
    }).catch(e => {
      this.setState({ isMounted: true });
      console.log(e);
      if (loading) {
        hideLoaderTimeout = setTimeout(() => { hideLoader(); }, 300);
      }
      if (history.location.pathname.indexOf("/login") === -1 &&
        history.location.pathname.indexOf("/qr-resource") === -1) {
        history.push(`/login`);
      }
    });
  }

  beforeUnload = () => {
    var token = window.storageGetItemValue("Auth-token");
    if (token) {
      var newLog: MemberLog = new MemberLog("/", "LeavingPage", "zbsmartspace", "Leave", "", navigator.userAgent);
      saveLog(newLog, false);
    }
  };

  handleAuthorization = async (token: string | null, callbackFn?: Function) => {
    if (token) {
      // return to previous page opened before to be re-rendered (only if not in home page)
      // checking if i have previously visited a page
      // In case of first access, force user to edit your profile
      this.setState({ authToken: token }, () => {
        showLoader();
        settingEvent = true;
        this.setEventSelected(async (redirect = true) => {
          if (redirect) {
            if (window.storageGetItemValue("redirectTo")) {
              history.push(window.storageGetItemValue("redirectTo"));
              window.storageRemoveItem("redirectTo");
            }
          }
          hideLoaderTimeout = setTimeout(() => { settingEvent = false; hideLoader() }, 200);
        })
      });
    }
    else {
      // logout, removing stored device token for current user
      if (history.location.pathname !== '/change-password/' && history.location.pathname !== '/forgot-password') {
        try {
          var newLog: MemberLog = new MemberLog("/", "logged-out", "", "logout", "", navigator.userAgent);
          saveLog(newLog);

          // const res = await axios.post("Authentication/logout", null, config);
          setTimeout(() => {
            window.removeStoredUserData();
            this.setState({
              authToken: null,
              myProfileData: null,
              myGroupData: null,
              achievements: null,
            }, () => { if (callbackFn) callbackFn() });
          }, 200);
        } catch (e) {
          setTimeout(() => {
            window.removeStoredUserData();
            this.setState({
              authToken: null,
              myProfileData: null,
              myGroupData: null,
              achievements: null,
            }, () => { if (callbackFn) callbackFn() });
          }, 200);
        }
      }
    }
  }

  handleShowNav = (isVisible: boolean) => {
    this.setState({ isNavVisible: isVisible });
  }

  setProfileData = (profileData: MembersTypeRes.IMember) => {
    if (!profileData.termsAndConditionsDigitalCourses) {
      this.setState({ myProfileData: profileData }, () => {
        // window.storageRemoveItem("redirectTo");
      });
    }
    else {
      this.setState({ myProfileData: profileData });
    }
  }

  setLanguage = (newLanguage: TMainLanguage, updateUserProfile: boolean = true) => {
    if (this.state.myProfileData && updateUserProfile) {
      updateUserLanguage(this.state.myProfileData, newLanguage);
    }
    window.storageSetItem("language", newLanguage);
    this.setState({ language: newLanguage });
  }

  setUIState = (newState: number) => {
    this.setState({ uiState: newState });
  }

  setTemplate = (newTemplate: TMainTemplate) => {
    this.setState({ template: newTemplate });
  }

  setHistoryStack = (newHistoryStack: IHistoryStack[]) => {
    sessionStorage.setItem("historyStack", JSON.stringify(newHistoryStack));
    // this.setState({ historyStack: historyStack });
    historyStack = newHistoryStack;
  }

  setGroupData = (groupData: MembersTypeRes.IGroupAssociation) => {
    this.setState({ myGroupData: groupData });
  }

  setFormActive = (form: IForm) => {
    this.setState({ activeForm: form }, () => {
      if (form === 'login') scrollToElementWithId("loginContainer", 0)
      if (form === 'registration') scrollToElementWithId("registrationContainer", 0)
    });
  }

  setEvents = (events: EventsTypeRes.IEvent[], callback?: any) => {
    this.setState({ events: events }, callback);
  }

  setEventSelected = (callback?: any) => {
    const threeMonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
    axios.get(`Events?sortField=Priority&limit=999&showArchivedEvents=true&eventsDateLimit=${threeMonthsAgo}`).then(res => {
      const response = res.data;
      if (response.status === 'success') {
        let events: EventsTypeRes.IEvent[] = response.data;
        events = filterMadridEvents(events);
        this.setEvents(events, !(events != null && events.length > 0) ? callback : null);
        if (events != null && events.length > 0) {
          this.updateSettingsByEvent(events, callback);
        }
        else {
          hideLoaderTimeout = setTimeout(hideLoader, 200);
        }
      }
    });
  }

  updateSettingsByEvent = async (events: EventsTypeRes.IEvent[] = this.state.events, fnCallback?: any) => {
    if (window.location.pathname !== "/login/problem-solved") {
      var parts = window.location.pathname.split(/\/|\?/);
      if (window.storageGetItemValue('redirectTo')) {
        parts = [...parts, ...window.storageGetItemValue('redirectTo').split(/\/|\?/)]
      }
      let eventSelected = events.find(e => e.eventSettings && parts.includes(e.eventSettings.eventPath));
      if (!eventSelected) {
        let eventArchived = this.state.eventsLight.find(e => e.archived === true && parts.find(part => part === e.eventPath));
        if (eventArchived) {
          await axios.get('Events/' + eventArchived.eventID).then(res => {
            const response = res.data;
            if (response.status === "success") {
              const data: EventsTypeRes.IEvent = response.data;
              eventSelected = data;
            }
          })
        }
      }

      if (eventSelected != null) {
        if (this.state.currentEvent === null || eventSelected.eventID !== this.state.currentEvent.eventID) {
          let isPollsVisible = false;
          let isChatVisible = false;
          let uiState = UIState.Registration;
          let template: TMainTemplate = 'Template01';
          let redirect = true;
          let isTens2022 = false;
          let isMadridDigital = false;
          let isMadridInPerson = false;

          if (eventSelected.eventSettings.chatRoomID > 0) {
            isChatVisible = true;
            window.chatRoomLive = eventSelected.eventSettings.chatRoomID.toString();
          }

          if (eventSelected.eventSettings.serviceChatRoomID > 0) {
            window.chatRoomBackstage = eventSelected.eventSettings.serviceChatRoomID.toString();
          }

          if (eventSelected.eventSettings.eventPollTypeID > 0) {
            isPollsVisible = true;
          }

          if (eventSelected.eventSettings.eventUIStateID > 0) {
            uiState = eventSelected.eventSettings.eventUIStateID;
          }

          if (eventSelected.eventSettings && eventSelected.eventSettings.eventTemplate) {
            template = eventSelected.eventSettings.eventTemplate.eventTemplateName as TMainTemplate;
          }

          if (eventSelected.eventID.toUpperCase() === '15FF2268-412F-4D5E-ABA9-62BAF45460D5') {
            isTens2022 = true;
          }

          if (eventSelected.eventID.toUpperCase() === 'AEEC89A6-7C7C-45D4-8E4F-0FEED69C0697') {
            isMadridDigital = true;
          }

          if (eventSelected.eventID.toUpperCase() === 'FB560F21-1F32-4E4C-9E51-CEC1B78E88B8') {
            isMadridInPerson = true;
          }

          const eventRoute = eventSelected.eventSettings.eventPath as TCurrentRoute;

          let isAttendeeOfCurrentEvent = false;

          if (eventSelected && eventSelected.eventAttendees) {
            const eventAttendee = eventSelected.eventAttendees.find(attendee => attendee.status === Enum.ParticipationStatuses.Participating)
            if (eventAttendee) {
              isAttendeeOfCurrentEvent = true;
            }
          }
          if (eventSelected.archived) {
            if (!history.location.pathname.includes('/personal-badge') &&
              // If the location is "members" or "event-attendees" we ignore the fact that the user is not an attendee
              !history.location.pathname.includes('/event-attendees') &&
              !history.location.pathname.includes('/agenda') &&
              !history.location.pathname.includes('/members') &&
              !history.location.pathname.includes('/on-demand-room')) {
              window.storageRemoveItem("redirectTo");
              history.replace(`/on-demand-room/${eventSelected.eventSettings.eventPath}`);
              redirect = false;
            }
          }
          else {
            if (!isAttendeeOfCurrentEvent &&
              !history.location.pathname.includes('/personal-badge') &&
              // If the location is "members" or "event-attendees" we ignore the fact that the user is not an attendee
              !history.location.pathname.includes('/event-attendees') &&
              !history.location.pathname.includes('/agenda') &&
              !history.location.pathname.includes('/members')) {
              history.replace(`/${eventSelected.eventSettings.eventPath}/personal-badge`);
              redirect = false;
            }
          }

          this.setState({
            currentEvent: eventSelected,
            currentRoute: eventRoute,
            uiState: uiState,
            template: template,
            msTeamsData: eventSelected.msTeamsData,
            isChatVisible: isChatVisible,
            isPollsVisible: isPollsVisible,
            isAttendeeOfCurrentEvent: isAttendeeOfCurrentEvent,
            isDemoEvent: eventSelected.eventStatus === "Demo",
            isTens2022: isTens2022,
            isMadridDigital: isMadridDigital,
            isMadridInPerson: isMadridInPerson
          }, () => {
            if (fnCallback) {
              fnCallback(redirect);
            } else {
              hideLoaderTimeout = setTimeout(hideLoader, 200);
            }
          });
        } else {
          fnCallback && fnCallback()
        }
      }
      else {
        window.chatRoomLive = "";
        window.chatRoomBackstage = "";

        this.setState({
          currentEvent: null,
          currentRoute: '',
          uiState: 1,
          template: 'Template01',
          msTeamsData: '',
          isChatVisible: false,
          isPollsVisible: false,
          isAttendeeOfCurrentEvent: false,
          isDemoEvent: false,
          isTens2022: false,
          isMadridDigital: false,
          isMadridInPerson: false
        }, () => {
          if (fnCallback) {
            fnCallback();
          }
          else {
            hideLoaderTimeout = setTimeout(hideLoader, 200);
          }
        });
      }
    }
  }

  clearHideLoaderTimer = () => {
    if (hideLoaderTimeout) {
      clearTimeout(hideLoaderTimeout);
    }
  }

  clearAuthentication = () => {
    historyStack = [];
    this.setState({
      authToken: null,
      myProfileData: null,
      myGroupData: null,
    });
  }

  render() {

    const {
      authToken, myProfileData, myGroupData,
      isRegistrationEnabled, path,
      activeForm,
      events, currentRoute, uiState,
      currentEvent,
      isTens2022,
      isMadridDigital,
      isMounted,
    } = this.state;

    return (
      <div className="app">
        {
          isMounted ?
            <>
              {/* One Signal notifications */}
              {/* {process.env.REACT_APP_MADRID_IN_PERSON_NOTIFICATIONS_ONE_SIGNAL === "true" && <ManagerOneSignal userData={myProfileData} />} */}
              {/* SignalR notifications*/}
              {myProfileData && authToken && <SignalRManager authToken={authToken} currentEvent={currentEvent} wsUrl={window.wsURL} showNotifications={true} />}
              {/* Stripe notifications */}
              {process.env.REACT_APP_MADRID_IN_PERSON_NOTIFICATIONS_STRIPE === "true" && <Notifications userData={myProfileData} path={path} />}
              {/* Toast notifications */}
              <MpaUi.Containers.NotificationManager millisecondsToShow={8000} />
              {/* TO REMOVE */}
              <ToastContainer />
              <SvgGradients />
              <div className="page-container">
                <ContextAppSettings.Provider value={{ ...this.state, setAuthToken: this.handleAuthorization, setProfileData: this.setProfileData }}>
                  <CheckLoginStatus />
                  {
                    <Navigation authToken={authToken} setToken={this.handleAuthorization} userData={myProfileData || null} groupData={myGroupData}
                      setFormActive={this.setFormActive} path={path} setLanguage={this.setLanguage} historyStack={historyStack} setHistoryStack={this.setHistoryStack} />
                  }

                  {/* {
                    <NavigationRefactor authToken={authToken} setToken={this.handleAuthorization} userData={myProfileData || null} groupData={myGroupData}
                    setFormActive={this.setFormActive} path={path} setLanguage={this.setLanguage} historyStack={historyStack} setHistoryStack={this.setHistoryStack} />
                  } */}

                  {
                    myProfileData && currentEvent &&
                    <>
                      <ManagerEventSteps userData={myProfileData} currentEvent={currentEvent} />
                      {/* <ManagerChatRooms currentEvent={currentEvent} /> */}
                    </>
                  }
                  <React.Suspense fallback={<div className="page-loader"></div>}>
                    <Switch>
                      <PrivateRoute path="/" exact Component={HomePageContainer}
                        Props={{ authToken: authToken, userData: myProfileData, groupData: myGroupData, events: events, setEvents: this.setEvents }}
                      />
                      <PrivateRoute path={`/${currentRoute}`} exact Component={(this.componentsByState as any)[uiState]}
                        Props={{ authToken: authToken, userData: myProfileData, groupData: myGroupData }}
                      />
                      <PrivateRoute path={`/${currentRoute}/live-page`} exact Component={LiveEventPageContainer}
                        Props={{ authToken: authToken, userData: myProfileData, groupData: myGroupData }}
                      />

                      {/* Login oidc routes */}
                      <Route path="/login" exact component={() =>
                        <RegistrationPageContainer
                          authToken={authToken}
                          profile={myProfileData}
                          setToken={this.handleAuthorization}
                          setProfile={this.setProfileData}
                          setGroup={this.setGroupData}
                          activeForm={activeForm}
                          setFormActive={this.setFormActive}
                        />
                      }
                      />
                      <Route path="/login/problem-solved" exact component={() =>
                        <RegistrationPageContainer
                          authToken={authToken}
                          profile={myProfileData}
                          setToken={this.handleAuthorization}
                          setProfile={this.setProfileData}
                          setGroup={this.setGroupData}
                          activeForm={activeForm}
                          setFormActive={this.setFormActive}
                          template="problem-solved"
                        />
                      }
                      />
                      <Route path="/post-login" exact component={PostLogin} />

                      <PrivateRoute path="/personal-badge" exact Component={PersonalBadge} Props={{ authToken: authToken, userData: myProfileData, groupData: myGroupData, events: events }} />
                      <PrivateRoute path="/my-card" exact Component={BusinessCard} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path="/manage-cards" exact Component={BusinessCard} Props={{ authToken: authToken, userData: myProfileData, groupManager: true }} />
                      <PrivateRoute path="/:event/personal-badge" exact Component={PersonalBadge} Props={{ authToken: authToken, userData: myProfileData, groupData: myGroupData, events: events }} />
                      <Route path="/register-success" exact component={() => <RegisterSuccess type="reg" />} />
                      <Route path="/register-success/problem-solved" exact component={() => <RegisterSuccess type="reg" template="problem-solved" />} />
                      <Route path="/edit-profile-success" exact component={() => <RegisterSuccess type="edit" />} />
                      <PrivateRoute path="/update-my-info" exact Component={RegisterPage} Props={{ authToken: authToken, type: 'edit', isRegistrationEnabled: isRegistrationEnabled }} />
                      <Route path="/forgot-password" exact component={() => <ForgotPassword />} />
                      <Route path="/forgot-password/problem-solved" exact component={() => <ForgotPassword template="problem-solved" />} />
                      <Route path="/forgot-password-success" exact component={() => <RegisterSuccess type="forgot-pwd" />} />
                      <Route path="/change-password" exact component={ChangePassword} />
                      <Route path="/change-password-success" exact component={() => <RegisterSuccess type="change-pwd" />} />

                      <PrivateRoute path={`/${currentRoute}/live-chat`} exact Component={LiveChat} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/live-chat/plenary-1`} Component={LiveChat} Props={{ authToken: authToken, userData: myProfileData, chatRoomID: process.env.REACT_APP_MADRID_CHAT_PLENARY_1_ID }} />
                      <PrivateRoute path={`/${currentRoute}/live-chat/plenary-2`} Component={LiveChat} Props={{ authToken: authToken, userData: myProfileData, chatRoomID: process.env.REACT_APP_MADRID_CHAT_PLENARY_2_ID }} />
                      <PrivateRoute path={`/${currentRoute}/backstage-chat`} Component={BackstageChat} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/faculty-panel`} Component={FacultyPanel} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/events-admin`} exact Component={EventAdmin} Props={{ authToken: authToken, additionalkey: currentEvent?.eventID }} />
                      <PrivateRoute path={`/${currentRoute}/file/:key`} exact Component={VideoViewer} Props={{ authToken: authToken }} />
                      <PrivateRoute path={`/${currentRoute}/breakout-room/:friendlyURL?`} exact Component={BreakoutRoom} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/zoom-meetings`} exact Component={ZoomMeetingsList} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/lounge-room`} exact Component={LoungeCafe} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/lounge-room/:friendlyURL?`} exact Component={LoungeCafeRoom} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/remote-clicker`} exact Component={RemoteClicker} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/survey`} exact Component={Survey} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/events-admin-madrid`} exact Component={EventsAdminMadrid} Props={{ authToken: authToken, userData: myProfileData }} />

                      {/* emea-mktg-meeting-2022 */}
                      <PrivateRoute path={`/${currentRoute}/gallery`} exact Component={GalleryHeroes} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/hall-of-fame`} exact Component={GalleryHeroesWrapper} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/on-demand-room/${currentRoute}`} exact Component={OnDemandArea} Props={{ authToken: authToken, userData: myProfileData, events: events, setEvents: this.setEvents }} />
                      <PrivateRoute path={`/on-demand-room`} exact Component={OnDemandArea} Props={{ authToken: authToken, userData: myProfileData, events: events, setEvents: this.setEvents }} />
                      <PrivateRoute path={`/speaker-room`} exact Component={BackstageArea} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/exhibition-room`} exact Component={ExhibitionArea} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/exhibition-room-preview`} exact Component={ExhibitionAreaPreview} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/exhibition-room-configurator`} exact Component={ExhibitionAreaConfigurator} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/sales-awards-2021-photo-album`} exact Component={EmeaAwardsTrip} Props={{ authToken: authToken, userData: myProfileData, destination: 'mauritius' }} />
                      <PrivateRoute path={`/st-lucia-awards`} exact Component={EmeaAwardsTrip} Props={{ authToken: authToken, userData: myProfileData, destination: 'st-lucia' }} />
                      <PrivateRoute path={`/vietnam-awards`} exact Component={Vietnam} Props={{ authToken: authToken, userData: myProfileData }} />
                      {/* <PrivateRoute path={`/st-lucia-awards`} exact Component={StLucia} Props={{ authToken: authToken, userData: myProfileData, destination: 'st-lucia'}} /> */}
                      <PrivateRoute path={`/madrid-photo-album`} exact Component={MadridPhotoAlbum} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/video-library-team-members`} exact Component={VideoLibraryTeamMembers} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/graphics-warehouse`} exact Component={WarehouseArea} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/users-list`} exact Component={UsersList} Props={{ authToken: authToken, userData: myProfileData }} />

                      {/* Events management */}
                      <PrivateRoute path="/events-list" exact Component={EventsList} Props={{ authToken: authToken, events: events, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/agenda`} exact Component={AgendaAppointmentsList} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/event-attendees`} exact Component={EventAttendees} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path={`/${currentRoute}/event-attendees/avaiable-users`} exact Component={AvaiableUsers} Props={{ authToken: authToken, userData: myProfileData }} />
                      {/* Cvent import */}
                      <PrivateRoute path={`/${currentRoute}/members/import`} exact Component={Cvent} Props={{ authToken: authToken }} />
                      <PrivateRoute path={`/${currentRoute}/members/import/:key`} Component={CventGuestsList} Props={{ authToken: authToken }} />

                      {/* views panel */}
                      <PrivateRoute path="/views-panel" Component={ViewsPanel} Props={{ authToken: authToken }} />
                      {/* notification panel */}
                      <PrivateRoute path={`/${currentRoute}/notifications-panel`} Component={NotificationsPanel} Props={{ authToken: authToken }} />
                      {/* booking panel */}
                      <PrivateRoute path={`/${currentRoute}/bookings-panel/`} exact Component={BookingsPanel} Props={{ authToken: authToken, userData: myProfileData, path: path }} />
                      <PrivateRoute path={`/${currentRoute}/bookings-panel/breakout-room/`} exact Component={BookingsPanel} Props={{ authToken: authToken, userData: myProfileData, path: path, type: Enum.ElementType.BreakoutRoom }} />
                      <PrivateRoute path={`/pdf-download/`} exact Component={DownloadCertificate} Props={{ authToken: authToken, userData: myProfileData }} />
                      {/* voting panel */}
                      <PrivateRoute path={`/${currentRoute}/voting-panel`} Component={VotingPanel} Props={{ currentEvent: currentEvent, authToken: authToken, userData: myProfileData }} />


                      {/* legal content */}
                      <Route path="/legal-notice"><LegalNotice /></Route>
                      <Route path="/terms-of-service"><TermsService /></Route>
                      <Route path="/privacy-policy"><PrivacyPolicy /></Route>
                      <Route path="/cookies-notice"><CookiesNotice /></Route>

                      {/* wheel Game */}
                      <PrivateRoute path={`/wheel-of-fortune/leaderboard`} exact Component={WheelOfFortuneLeaderboard} Props={{ authToken: authToken }} />
                      <PrivateRoute path={`/wheel-of-fortune`} exact Component={WheelOfFortune} Props={{ authToken: authToken }} />
                      <PrivateRoute path={`/challenge`} exact Component={TensChallenge} Props={{ authToken: authToken, userData: myProfileData, groupData: myGroupData }} />
                      <PrivateRoute path={`/wheel-of-fortune-live`} Component={WheelOfFortuneLive} Props={{ authToken: authToken }} />

                      {/* Madrid - In Person */}
                      {/* <PrivateRoute path="/problem-solved-madrid/e-journal" Component={InPersonEJournal} Props={{ authToken: authToken, userData: myProfileData }}/> */}
                      <PrivateRoute path="/problem-solved-madrid/interaction-toolbox/:toolID/:plenary" Component={InPersonLiveSingleTool} Props={{ authToken: authToken }} />
                      <PrivateRoute path="/problem-solved-madrid/3d-interactive-map/:startPoint" Component={InPerson3DInteractiveMap} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path="/problem-solved-madrid/:path" Component={InPerson} Props={{ authToken: authToken, userData: myProfileData, path: path, }} />
                      <Route path="/problem-solved-madrid-interactive-program" render={(props) =>
                        // <InPersonInteractiveProgram {...props} authToken={authToken} />
                        <RedirectTo href="https://www.zbsmartspace.com/shoulder-symposium/agenda" />
                      } />

                      <Route path="/madrid-journal-editor" render={(props) => <Editor />} />
                      <Route path="/madrid-journal-preview" render={(props) => <Preview />} />
                      <PrivateRoute path={`/madrid-journal/gallery`} exact Component={Gallery} Props={{ authToken: authToken, userData: myProfileData }} />
                      <PrivateRoute path="/madrid-journal" Component={Journal} Props={{ authToken: authToken, userData: myProfileData, path: path, }} />

                      <PrivateRoute path="/digital-blackboard" Component={DigitalBlackboard} Props={{ authToken: authToken, userData: myProfileData }} />

                      <Route path="/qr-resource" render={() => <QrResource />} />
                      <Route path="/install-instructions" render={() => <InstallInstructions />} />
                      <Route path="/madrid-shoulder-chairs" render={() => <MadridShoulderLandingPage />} />
                      {/* <Route path="/lets-talk-about-infection-2024" render={(props) =>
                        <RedirectTo href="/contemporary-approaches-to-infection-management-2024" />
                      } /> */}
                      <Route path="/lets-talk-about-infection-2024" render={() => <ContemporaryApproachesInfectionLandingPage />} />
                      

                      <PrivateRoute path="/tools/digi-pad" Component={DigiPad} Props={{ authToken: authToken, userData: myProfileData }} />

                      {/* Madrid - Digital */}
                      {
                        isMadridDigital &&
                        <>
                          <PrivateRoute path={`/${currentRoute}/panel-discussion`} Component={PanelDiscussion} Props={{ authToken: authToken, userData: myProfileData, path: path, }} />
                          <PrivateRoute path={`/${currentRoute}/meet-the-expert`} Component={MeetTheExpert} Props={{ authToken: authToken, userData: myProfileData, path: path, }} />
                        </>
                      }

                      <Route component={PageNotFound} />
                    </Switch>

                  </React.Suspense>

                  {/* footer  */}
                  <ZoomFallbackContainer />
                  <AchievementUnlocked />
                  <AudioManager />
                  <MpaUi.Containers.PreviewFileManager className="custom-preview" />
                  <MpaUi.Containers.ConfirmDialogManager />
                  <ModalMenu />
                </ContextAppSettings.Provider>
              </div>
              <Footer path={path} userData={myProfileData} />
            </>
            :
            null
        }

      </div>
    )

  }

}

export default App;
